import Router from "./routes/Router.js";

function App() {

    console.log("App")

    return (

        <Router/>
    )
}

export default App;